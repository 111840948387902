import {differenceInHours, parseISO} from "date-fns"
import {cancelarAssinatura} from "@/domain/financeiro/services"

export default {
    methods: {
        periodicidadeString(p) {
            switch (Number(p)) {
                case 1:
                    return 'Mensal'
                case 3:
                    return 'Trimestral'
                case 6:
                    return 'Semestral'
                case 12:
                    return 'Anual'
                case 24:
                    return 'Bienual'
                default:
                    return `A cada ${p} meses`
            }
        },
        atraso(d) {
            return Math.ceil(differenceInHours(parseISO(d.date || d), new Date()) / 24) * -1
        },
        cancelarAssinatura(id) {
            this.$uloc.dialog({
                title: 'Cancelar assinatura',
                message: 'Você tem certeza que deseja cancelar esta assinatura? Não será possível reativá-la. Caso queira contratar novamente o mesmo serviço, será necessário uma nova contratação',
                ok: 'Sim',
                cancel: 'Desistir',
                color: 'negative'
            }).then(() => {
                this.$uloc.loading.show({message: 'Cancelando assinatura'})
                cancelarAssinatura(id)
                    .then(response => {
                        this.load()
                        this.$uloc.loading.hide()
                    })
                    .catch(error => {
                        this.$uloc.loading.hide()
                        this.alertApiError(error)
                    })
            }).catch(() => {
            })
        }
    }
}
