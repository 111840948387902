<template>
  <div>
    <div class="suporte-page-content assinaturas">
      <div v-if="loading" class="flex items-center"><sl-loading class="size-18 m-r-sm" content="" /> Buscando assinaturas ativas...</div>
      <template v-if="!loading">
      <cobranca-info :cliente="cliente" />
      <assinatura-principal-info :cliente="cliente" />
      </template>
      <cliente-assinaturas @load="load" />
    </div>
  </div>
</template>

<script>
import CobrancaInfo from "components/suporte/cliente/components/assinatura/CobrancaInfo.vue"
import SlLoading from "components/layout/components/Loading.vue"
import AssinaturaPrincipalInfo from "components/suporte/cliente/components/assinatura/AssinaturaPrincipalInfo.vue";
import ClienteAssinaturas from "components/suporte/cliente/components/assinatura/AssinaturasList.vue";

export default {
  name: "Assinaturas",
  data () {
    return {
      loading: true,
      assinaturas: null,
      cliente: null
    }
  },
  mounted() {
  },
  methods: {
    load (data) {
      console.log('Load: ', data)
      this.loading = false
      this.cliente = data.cliente
    }
  },
  components: {
    ClienteAssinaturas,
    AssinaturaPrincipalInfo,
    SlLoading,
    CobrancaInfo
  }
}
</script>
