import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 1
export const listName = 'suporte.cliente.assinaturas'
export const listStorage = tableColumnsCache(listName, [
    {label: 'ID', name: 'id', active: false, sortable: false, ordering: 1},
    {label: 'Início', name: 'dataInicio', active: false, sortable: false, ordering: 1},
    {label: 'Encerramento', name: 'dataFim', active: false, sortable: false, ordering: 1},
    {label: 'Descrição', name: 'descricao', active: true, sortable: false, ordering: 1},
    {label: 'Cobrança', name: 'criterioCobranca', active: true, sortable: false, ordering: 1},
    {label: 'Valor', name: 'valor', active: true, sortable: false, ordering: 1},
    {label: 'Próximo Vencimento', name: 'dataProximoVencimento', active: true, sortable: false, ordering: 1},
    {label: 'Situação', name: 'status', active: true, sortable: false, ordering: 1},
    {label: 'Detalhes', name: 'detalhes', active: true, sortable: false, ordering: 1},
], listVersion)
