<template>
  <div class="assinatura-principal-info">
    <div class="title">Licença de uso:</div>
    <div class="erp-alert1">
      <p>Para garantir serviço SaaS permanente em nuvem com a Suporte Leilões, você é cobrado de forma recorrente de acordo com seu ciclo de cobrança.</p>
      <table class="table-info no-border">
        <tr>
          <td>Plano:</td>
          <td>{{ cliente.plano.nome }}</td>
        </tr>
        <tr>
          <td>Valor:</td>
          <td>R$ {{ cliente.plano.mensalidade|moeda }} <span class="font-normal" v-if="Number(cliente.plano.mensalidade) !== Number(cliente.mensalidade)">reajustado para <u>{{ cliente.mensalidade|moeda }}</u></span></td>
        </tr>
        <tr v-if="cliente.mensalidadeProximoVencimento">
          <td>Próximo vencimento:</td>
          <td>{{ cliente.mensalidadeProximoVencimento|formatDate('dd/MM/yyyy') }}</td>
        </tr>
        <tr v-if="cliente.mensalidadePeriodicidade">
          <td>Periodicidade:</td>
          <td>
            <span v-if="cliente.mensalidadePeriodicidade === 1">Mensal</span>
            <span v-if="cliente.mensalidadePeriodicidade === 3">Trimestral</span>
            <span v-if="cliente.mensalidadePeriodicidade === 6">Semestral</span>
            <span v-if="cliente.mensalidadePeriodicidade === 12">Anual</span>
            <span v-if="cliente.mensalidadePeriodicidade === 24">Bianual</span>
          </td>
        </tr>
        <tr>
          <td>Status:</td>
          <td class="flex items-center">
            <div class="text-uppercase-first">{{ cliente.status }}</div>
            <u-btn label="Cancelar assinatura" no-caps color="negative" disable class="m-l-md b-radius-3px" outline>
              <u-tooltip :offset="[10,10]">Para cancelar a assinatura entre em contato com seu gerente de contas na SL</u-tooltip>
            </u-btn>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import {UTooltip} from "uloc-vue"

export default {
  name: "AssinaturaPrincipalInfo",
  props: {
    cliente: {required: true}
  },
  components: {
    UTooltip
  }
}
</script>
