<template>
  <div class="assinatura-cobranca-info">
    <div class="title">Informações de cobrança:</div>
    <div class="" v-if="visualizar">
      <table class="table-info">
        <tr>
          <td>Nome:</td>
          <td>{{ cliente.pessoa.name }}</td>
        </tr>
        <tr>
          <td>E-mail:</td>
          <td>{{ cliente.pessoa?.emails?.[0]?.email }}</td>
        </tr>
        <tr>
          <td>Telefone:</td>
          <td>{{ cliente.pessoa?.phoneNumbers?.[0]?.phoneNumber }}</td>
        </tr>
        <tr>
          <td>Endereço:</td>
          <td>{{ cliente.pessoa?.addresses?.[0]?.address }} {{ cliente.pessoa?.addresses?.[0]?.complement }}, {{ cliente.pessoa?.addresses?.[0]?.number }}, {{ cliente.pessoa?.addresses?.[0]?.city }} - {{ cliente.pessoa?.addresses?.[0]?.state }} - {{ cliente.pessoa?.addresses?.[0]?.country }}. {{ cliente.pessoa?.addresses?.[0]?.zip }}</td>
        </tr>
        <tr>
          <td>CPF / CNPJ:</td>
          <td>{{ cliente.pessoa?.document|formataCpfCnpj2 }}</td>
        </tr>
      </table>
    </div>
    <div v-else>
      <table class="table-info no-border">
        <tr>
          <td style="width: 100px !important; min-width: 100px !important;">Nome:</td>
          <td>{{ cliente.pessoa.name }}</td>
        </tr>
      </table>
      <a @click="visualizar = true" style="text-decoration: underline">Visualizar dados de cobrança</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "CobrancaInfo",
  props: {
    cliente: {required: true}
  },
  data () {
    return {
      visualizar: false
    }
  }
}
</script>
