<template>
  <router-view v-if="$route.name !== 'suporte.cliente.faturas'"></router-view>
  <div v-else class="h-full">
    <div class="suporte-cliente-tarefas">
      <div class="sheader">
        <h2 class="component-title" style="max-width: 400px">Faturamento e Assinaturas</h2>
        <div class="btns">

        </div>
      </div>

      <div class="cliente-faturas">
        <div class="erp-list list-tasks no-pad">
          <div class="erp-list-top-btns items-end">
            <div class="actives-btns col-grow-1" style="margin-left: 60px">
              <u-btn @click="changeListType('faturas')" class="active-table-btn" label="Pagamentos" no-caps :class="{active: listType === 'faturas'}" />
              <u-btn @click="changeListType('assinaturas')" class="active-table-btn" label="Assinaturas" no-caps :class="{active: listType === 'assinaturas'}" />
              <u-btn @click="changeListType('contratos')" class="active-table-btn" label="Contratos de Serviço" no-caps :class="{active: listType === 'contratos'}" />
            </div>
          </div>
        </div>
        <div class="suporte-page-content" v-if="listType === 'faturas'">
          <div v-if="totalDue" class="erp-alert1 danger">
            Você possui <strong>{{dueInvoices.length}} <span v-if="dueInvoices.length > 1">pagamentos pendentes </span><span v-else>pagamento pendente</span></strong> <strong class="text-negative"><u>em atraso</u></strong> no sistema no total de <strong>R${{totalDue|moeda}}</strong>. Pague agora e evite suspensão dos seus serviços.
          </div>
          <u-table
              ref="table"
              color="primary"
              :data="table.serverData"
              :columns="table.columns"
              :visible-columns="visibledColumns"
              :filter="table.busca"
              row-key="id"
              :pagination.sync="table.serverPagination"
              @request="request"
              :loading="table.loading"
              class="erp-table table-v2"
              :hide-bottom="Number(table.serverPagination.rowsNumber) < table.serverPagination.rowsPerPage"
              :rows-per-page-options="[20, 50, 100]"
          >
<!--            <template slot="top" slot-scope="props">
            </template>-->
            <u-tr slot="header" slot-scope="props">
              <!--        <u-th auto-width v-if="mode !== 'cliente'">
                        <u-checkbox
                            v-model="props.selected"
                            :indeterminate="props.partialSelected"
                            size="xs"
                        />
                      </u-th>-->
              <u-th v-for="col in props.cols" :key="col.name" :props="props" :class="'col-' + col.name">
                <div v-if="col.name === 'options'" class="text-center">
                  <u-btn style="margin: -10px 0 -6px" @click="listOptions" label="Configurar lista" rounded round flat no-caps size="xs" icon="cog" icon-type="fa" color="grey-4" class="text-grey-8"/>
                </div>
                <template v-else>{{col.label}}</template>
              </u-th>
            </u-tr>
            <u-tr slot="body" slot-scope="props" :props="props">
              <u-td key="id" :props="props">
                {{ props.row.id }}
              </u-td>
              <u-td key="data" :props="props">
                {{ props.row.dataVencimento|formatDate('dd/MM/yyyy') }}
              </u-td>
              <u-td key="descricao" :props="props">
                {{ props.row.descricao }}
              </u-td>
              <u-td key="valor" :props="props">
                R${{ props.row.valor|moeda }}
              </u-td>
              <u-td key="fatura" :props="props">
                <div v-if="props.row.pago">
                  <a class="text-positive" @click="$router.push({name: 'suporte.cliente.faturas.fatura', params: {id: props.row.id}})">Pago</a>
                </div>
                <div v-else>
                  <router-link :to="{name: 'suporte.cliente.faturas.pagarFatura', params: {id: props.row.id}}" class="text-primary">Pagar</router-link>
                </div>
              </u-td>
            </u-tr>
          </u-table>
          <div v-if="Number(table.serverPagination.rowsNumber === 0)">Nenhum registro até o momento.</div>
        </div>
        <assinaturas v-if="listType === 'assinaturas'" />
        <contratos v-if="listType === 'contratos'" />
      </div>
    </div>
  </div>
</template>

<script>

import {listName, listStorage} from "components/suporte/cliente/config/listFaturas"
import filters from "@/domain/tarefas/helpers/filtersCliente"
import listOptions from "components/usuario/v2/window/listOptions"
import {UTable, UTh, UTr, UTd, UCheckbox, date, LocalStorage, UTooltip, UProgress} from 'uloc-vue'
import {datePtToEn} from "@/utils/date"
import {businessInvoices as list} from "@/domain/financeiro/services"
import {erpDefs} from "@/definitions"
import Assinaturas from "components/suporte/cliente/components/Assinaturas.vue"
import Contratos from "components/suporte/cliente/components/Contratos.vue"

export default {
  name: "ClienteFaturas",
  beforeCreate() {
    this.listStorage = listStorage
  },
  data () {
    return {
      listName: listName,
      listStorage: this.listStorage,
      response: {},
      listType: 'faturas',
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 1, // specifying this determines pagination is server-side
          rowsPerPage: 100
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        })/*.concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ])*/,
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      },
    }
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    },
    dueInvoices () {
      return erpDefs.dueInvoices
    },
    totalDue () {
      return erpDefs.totalDue
    }
  },
  components: {
    Contratos,
    Assinaturas,
    UTable,
    UTh,
    UTr,
    UTd,
  },
  mounted() {
    this.$route.name === 'suporte.cliente.faturas' && this.load()
  },
  activated() {
    this.$route.name === 'suporte.cliente.faturas' && this.load()
  },
  watch: {
    '$route' () {
      this.$nextTick(() => {
        this.$route.name === 'suporte.cliente.faturas' && this.load()
      })
    },
    'listType' (v) {
      if (v === 'faturas') {
        this.load()
      }
    }
  },
  methods: {
    request({pagination, filter}) {

      // this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || (!this.table.filters.data2 || this.table.filters.data2.length < 10)) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.table.filters.tipoData && extraFilters.push(`&typeDate=${this.table.filters.tipoData}`)
      this.table.filters.search && extraFilters.push(`&search=${String(this.table.filters.search).replace('#', '_hash-')}`)
      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.active && extraFilters.push(`&active=${this.table.filters.active}`)
      this.table.filters.status && extraFilters.push(`&status=${this.table.filters.status.id}`)

      if (this.extraFilters && Array.isArray(this.extraFilters)) {
        this.extraFilters.map(ef => {
          extraFilters.push('&' + ef)
        })
      }

      this.table.loading = true
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'dataVencimento'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : true}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros, false)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    load () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    listOptions: listOptions,
    updateListDefinition(newConfig) {
      LocalStorage.set(this.listName, newConfig)
      this.listStorage = newConfig
    },
    changeListType (v) {
      this.listType = v
    }
  }
}
</script>
