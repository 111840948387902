const filters = {
    id: null,
    search: null,
    descricao: null,
    tipo: [],
    internalStatus: [],
    createdAt: null,
    prazo: null,
    status: null,
    periodo: null,
    data1: null,
    data2: null,
    tipoData: 'createdAt',
    onlyOwnerTasks: false,
    dueFilter: false,
    participants: [],
    participantFilter: 'participant', // or owner
    priority: null,
    cliente: null,
    typeFilter: null
}

export default filters
