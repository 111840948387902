import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 0
export const listName = 'suporte.cliente.faturas'
export const listStorage = tableColumnsCache(listName, [
    {label: 'ID', name: 'id', active: false, sortable: false, ordering: 1},
    {label: 'Data', name: 'data', active: true, sortable: false, ordering: 1},
    {label: 'Descrição', name: 'descricao', active: true, sortable: false, ordering: 1},
    {label: 'Valor', name: 'valor', active: true, sortable: false, ordering: 1},
    {label: 'Fatura', name: 'fatura', active: true, sortable: false, ordering: 1},
], listVersion)
