<template>
  <div>
    <div class="suporte-page-content assinaturas">
      <div v-if="loading" class="flex items-center"><sl-loading class="size-18 m-r-sm" content="" /> Buscando assinaturas ativas...</div>
      <cliente-contratos @load="load" />
    </div>
  </div>
</template>

<script>
import SlLoading from "components/layout/components/Loading.vue"
import ClienteContratos from "components/suporte/cliente/components/assinatura/Contratos.vue"

export default {
  name: "Contratos",
  data () {
    return {
      loading: true,
      assinaturas: null,
      cliente: null
    }
  },
  mounted() {
  },
  methods: {
    load (data) {
      console.log('Load: ', data)
      this.loading = false
      this.cliente = data.cliente
    }
  },
  components: {
    ClienteContratos,
    SlLoading,
  }
}
</script>
