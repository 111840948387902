import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 0
export const listName = 'suporte.cliente.contratos'
export const listStorage = tableColumnsCache(listName, [
    {label: 'ID', name: 'id', active: false, sortable: false, ordering: 1},
    {label: 'Início', name: 'dataInicio', active: true, sortable: false, ordering: 1},
    {label: 'Encerramento', name: 'dataFim', active: true, sortable: false, ordering: 1},
    {label: 'Descrição', name: 'descricao', active: true, sortable: false, ordering: 1},
    {label: 'Cobrança', name: 'criterioCobranca', active: true, sortable: false, ordering: 1},
    {label: 'Valor', name: 'valor', active: true, sortable: false, ordering: 1},
    {label: 'Situação', name: 'status', active: true, sortable: false, ordering: 1},
    {label: 'Detalhes', name: 'detalhes', active: true, sortable: false, ordering: 1},
], listVersion)
